import React from "react"
import { graphql } from "gatsby"
import LayOut from "../components/layout"
import BlocksContent from "../components/blocksContent"
import RecentBlog from "./recent-blogs"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import "./blog-template.sass"
import { navigate } from "gatsby"
import Layout from "@components/layout"

const BlogTemplate = ({ data }) => {
  const {
    allSanityBlogs: {
      edges: [{ node: blog }],
    },
  } = data

  const onClick = e => {
    e.preventDefault()
    navigate(-1)
  }

  const { seo } = blog

  return (
    <Layout>
      <SEO
        title={seo?.pageTitle ? seo?.pageTitle : blog.title}
        description={seo?.pageDescription ? seo?.pageDescription : blog.excerpt}
        image={seo?.ogImage?.asset?.url ? seo?.ogImage?.asset?.url : data.image}
        url={`/${blog.slug.current}`}
        post="article"
      />
      <div id="blog_template">
        <div className="container">
          <span>{blog.publishdate || ""}</span>
          <h2>{blog.title || ""}</h2>
          <a onClick={onClick}>&lt; Back</a>
          <div className="content">
            <div className="main-content">
              <GatsbyImage
                image={blog.featureImage.asset.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                alt={blog.title}
                className="featureBlogImage"
              />
              <div className="content-innner">
                <BlocksContent blocks={blog.content._rawColumnContent} />
              </div>
              {blog.authorName && (
                <p>
                  <strong>Written by {blog.authorName}</strong>
                </p>
              )}
            </div>

            <div className="side-bar">
              <RecentBlog currentSlug={blog.slug.current || ""} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query singleBlogQuery($slug: String) {
    allSanityBlogs(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          title
          slug {
            current
          }
          publishdate(formatString: "MMM DD, YYYY")
          authorName
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          excerpt
          content {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          seo {
            ...sanitySeo
          }
        }
      }
    }
  }
`

export default BlogTemplate

/*
<LayOut>
      <SEO
        title={blog.title || ""}
        description={blog.excerpt || ""}
        image={data.image || null}
        url={`/${blog.slug.current}`}
        post="article"
      />
      <div id="blog_template">
        <div className="container">
          <span>{blog.publishdate || ""}</span>
          <h2>{blog.title || ""}</h2>
          <a onClick={onClick}>&lt; Back</a>
          <div className="content">
            <div className="main-content">
              <GatsbyImage
                image={blog.featureImage.asset.gatsbyImageData}
                imgStyle={{ objectFit: "contain" }}
                alt={blog.title}
                className="featureBlogImage"
              />
              <BlocksContent blocks={blog.content._rawColumnContent} />
              {blog.authorName && (
                <p>
                  <strong>Written by {blog.authorName}</strong>
                </p>
              )}
            </div>

            <div className="side-bar">
              <RecentBlog currentSlug={blog.slug.current || ""} />
            </div>
          </div>
        </div>
      </div>
    </LayOut>
    */
